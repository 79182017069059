/**
* Number.prototype.format(n, s, c)
*
* @param integer precision: length of decimal
* @param mixed   delimiter: sections delimiter
* @param mixed   separator: decimal delimiter
*/

Number.prototype.format = function (precision = 2, delimiter = ',', separator = '.') {
  var regex = '\\d(?=(\\d{' + 3 + '})+' + (precision > 0 ? '\\D' : '$') + ')',
    num = this.toFixed(Math.max(0, ~~precision));

  return (separator ? num.replace('.', separator) : num).replace(new RegExp(regex, 'g'), '$&' + (delimiter || ','));
};

(function () {
  class Common {
    constructor() {
      this.ui = {};

      // BY Class
      this.ui.jsOnlyNumber = ".js-only-number";
      this.ui.jsOnlyLetter = ".js-only-letter";
      this.ui.jsDatepicker = ".js-datepicker";
      this.ui.jsFormValidation = ".js-need-validation";
      this.ui.jsIntNumber = ".js-int-number";
      this.ui.jsFloatNumber = ".js-float-number";
      this.ui.jsSelect2 = ".select2";
      this.ui.resetFilter = "#clearFilter";
      this.ui.textUpperCase = ".text-uppercase";
      this.ui.jsLoading = ".js-loading";
      this.ui.jsSignature = ".js-signature";
      this.ui.jsBtnSubmit = ".js-requires-approval-btn";
      this.ui.jsConfirmModalRemoteLink = ".js-confirm-link";
    };

    loadComponents() {
      this.minDateLoadPicker();
      this.loadTooltips();
      this.hideLoadTooltips();
      this.onlyNumber();
      this.loadDatepicker();
      this.loadSelect2();
      this.resetFilter();
    };

    onlyLetter() {
      $(this.ui.jsOnlyLetter).on('keydown', (evt) => {
        var charCode = (evt.which) ? evt.which : evt.keyCode;

        if (charCode == 8 || charCode == 0) return true;
        validKeys = /[A-Za-z\s]/;
        key = String.fromCharCode(charCode);
        return validKeys.test(key);
      });
    }

    onlyNumber() {
      $(this.ui.jsOnlyNumber).on('keydown', (evt) => {
        const { value, maxLength } = evt.target;

        var keys = [
          8, // backspace
          9, // tab
          13, // enter
          17, // control
          46, // delete
          37, 39, 38, 40, // arrow keys<^>v
          67, // c
          86, // v
          110, // dot
          190 // dot
        ]

        var charCode = (evt.which) ? evt.which : evt.keyCode;

        if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105) && $.inArray(event.which, keys) == -1) {
          return false;
        }

        if (evt.target.hasAttribute('maxlength')) {
          if (String(value).length >= maxLength && $.inArray(event.which, keys) == -1) {
            evt.preventDefault();
            return false;
          }
        }

        return true;
      });
    };

    minDateLoadPicker() {
      // $('input[type="date"]').attr('min', '2021-10-01');
    };

    // Application-wide datepicker setup
    loadDateRangePicker(options) {
      const operation = this;
      var options = options || {};
      var query = options.query
      var custom_end_date = options.allow_end_date

      if (typeof query != 'undefined') {
        $datepickers = $(query);
      } else {
        $datepickers = $('input.js-daterangepicker');
      }

      $datepickers.daterangepicker({
        autoUpdateInput: false,
        showDropdowns: true,
        minYear: 2021,
        autoApply: true,
        ranges: {
          'Hoy': [moment(), moment()],
          'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Últimos 7 Días': [moment().subtract(6, 'days'), moment()],
          'Últimos 30 Días': [moment().subtract(29, 'days'), moment()],
          'Este Mes': [moment().startOf('month'), moment().endOf('month')],
          'Último Mes': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        locale: {
          format: "MM/DD/YYYY",
          separator: " - ",
          applyLabel: "Aceptar",
          cancelLabel: "Borrar filtro",
          fromLabel: "Desde",
          toLabel: "Hasta",
          customRangeLabel: "Rango",
          weekLabel: "W",
          daysOfWeek: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
          monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Deciembre"],
          firstDay: 1
        },
        minDate: "10/01/2021",
        opens: "center"
      }, function (start, end, label) {
        // console.log('New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')');
      }).on('apply.daterangepicker', function (ev, picker) {
        $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
      }).on('cancel.daterangepicker', function (ev, picker) {
        $(this).val('');
      });

      $datepickers.val('');

      // if(typeof custom_end_date != 'undefined') {
      //  var endDate = moment().format('DD-MM-YYYY');
      //  $datepickers.datepicker("setEndDate", endDate);
      //}
    };

    loadDatepicker(options) {
      var options = options || {};
      var start_date_from = options.start_date_from;

      $(this.ui.jsDatepicker).bootstrapMaterialDatePicker({
        format: 'DD MMM YYYY',
        clearButton: true,
        weekStart: 1,
        time: false,
        switchOnClick: true,
        lang: 'es',
        cancelText: 'Cancelar',
        okText: 'OK',
        clearText: 'Borrar'
      });

      if (typeof start_date_from != 'undefined') {
        $(this.ui.jsDatepicker).bootstrapMaterialDatePicker('setMinDate', start_date_from);
      }
    };

    loadDatePickerFromTo(from, to) {
      $(from).on('change', function (e, date) {
        $(to).attr('min', $(this).val())
      });

      $(to).on('change', function (e, date) {
        $(from).attr('max', $(this).val())
      });
    };

    setMinMaxDatePicker(element, min, max) {
      $(element).attr('min', min)
      $(element).attr('max', max)
    };

    needFormValidation(options) {
      var options = options || {};

      $.extend($.validator.defaults, {
        errorElement: 'span',
        errorClass: 'invalid-feedback',
        success: function (label, element) {
          label.parent().removeClass('error');
          label.remove();
        },
        errorPlacement: function (error, element) {
          if (element.parent('.input-group').length || element.prop('type') === 'checkbox' || element.prop('type') === 'radio') {
            error.insertAfter(element.parent());
          } else if ((element.prop('type') === 'select-multiple' && element.hasClass("select2")) || $(element).hasClass("select2")) {
            error.insertAfter(element.closest("div").find("span.select2"));
          } else {
            error.insertAfter(element);
          }
        },
        highlight: function (element, errorClass, validClass) {
          if (($(element).prop('type') === 'select-multiple' && $(element).hasClass("select2")) || $(element).hasClass("select2")) {
            $(element).closest("div").find("span.select2-selection").addClass("is-invalid");
          } else {
            $(element).addClass("is-invalid");
          }
        },
        unhighlight: function (element, errorClass, validClass) {
          if (($(element).prop('type') === 'select-multiple' && $(element).hasClass("select2")) || $(element).hasClass("select2")) {
            $(element).closest("div").find("span.select2-selection").removeClass("is-invalid");
          } else {
            $(element).removeClass("is-invalid");
          }
        }
      });

      $.validator.addMethod("greaterThanEqual", function (value, element, params) {
        var target = $(params[0]);

        if (this.settings.onfocusout && target.not(".validate-greaterThanEqual-blur").length) {
          target.addClass("validate-greaterThanEqual-blur").on("blur.validate-greaterThanEqual", function () {
            $(element).valid();
          });
        }

        return Number(value) >= Number(target.val());
      }, 'Must be greater than {1}.');

      $.validator.addMethod("minAge", function (value, element, min) {
        var today = new Date();
        var birthDate = new Date(value + "T00:00:00");
        var age = today.getFullYear() - birthDate.getFullYear();

        if (age > min + 1) {
          return true;
        }

        var m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        return age >= min;
      }, 'Debes tener mínimo {0} años.');

      $.validator.addMethod("maxAge", function (value, element, max) {
        var today = new Date();
        var birthDate = new Date(value + "T00:00:00");
        var age = today.getFullYear() - birthDate.getFullYear();

        if (max > age) {
          return true;
        }

        var m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() > birthDate.getDate())) {
          age++;
        }

        return max >= age;
      }, 'La edad máxima es {0} años.');

      $.validator.addMethod("equalToParam", function (value, element, param) {
        return value == param;
      }, "Por favor, introduzca la palabra correctamente.");

      $.validator.addMethod("ValidateDNI", function (value, element) {
        var data = { "dni": value };
        var status;

        $.ajax({
          type: "GET",
          url: '/js_validate_unique_loan',
          dataType: "json",
          data: data,
          cache: false,
          async: false
        }).done(function (data) {
          status = data.status;
        })

        return !status;
      }, 'El Cliente no califica para el préstamo.');

      $.validator.addMethod("ValidateIMEI", function (value, element) {
        var data = { "imei": value };
        var status;

        $.ajax({
          type: "GET",
          url: '/js_validate_unique_imei',
          dataType: "json",
          data: data,
          cache: false,
          async: false
        }).done(function (data) {
          status = data.status;
        })

        return !status;
      }, 'El IMEI ya se encuentra registrado.');

      $.validator.addMethod("ValidateIMEIKnox", function (value, element) {
        var data = { "imei": value, 'knox': true };
        var status;

        $.ajax({
          type: "GET",
          url: '/js_validate_unique_imei',
          dataType: "json",
          data: data,
          cache: false,
          async: false
        }).done(function (data) {
          status = data.status;
        })

        return !status;
      }, 'El IMEI ya se encuentra registrado.');

      $.validator.addMethod("formatDNI", function (value, element) {
        if (document.documentElement.lang == 'es') {
          return /^(PE|\dPI|E|N|\d{1,2})-\d{1,4}-\d{1,6}$/.test(value);
        } else if (document.documentElement.lang == 'es_slv') {
          return /^\d{8}-\d{1}$/.test(value);
        } else if (document.documentElement.lang == 'es_nic') {
          return /^([0-6][\d]{2})-([0-2][\d]|3[0-1])(0[1-9]|1[0-2])([\d]{2})-([\d]{4}[A-Z])$/.test(value);
        }
      }, 'Formato inválido.');

      $.validator.addMethod("validateCellphone", function (value, element, options) {
        var options = options || {};
        var loan_id = options.loan_id || "true";
        var status;

        if (loan_id == "true") {
          var data = { "cellphone": value };
        } else {
          var data = { "cellphone": value, loan_id: parseInt(loan_id) };
        }

        $.ajax({
          type: "GET",
          url: '/js_validate_cellphone',
          dataType: "json",
          data: data,
          cache: false,
          async: false
        }).done(function (data) {
          status = data.status;
        })

        return !status;
      }, 'El número celular se encuentra asociado a un préstamo activo');

      $.validator.addMethod("maxPrice", function (value, element, param) {
        value = parseFloat(value.replaceAll(',', ''))
        return this.optional(element) || value <= param;
      }, 'El precio máximo de venta es $ {0}.');

      $.validator.methods.email = function (value, element) {
        return this.optional(element) || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
      };

      $('.js-need-validation').validate(options);
    };

    loadValidateBirthDate() {
      $(this.ui.customerBirthDate).rules('add', {
        maxAge: 75,
        minAge: 18
      });
    }

    loadSelect2Ajax(options) {
      const operation = this;
      var options = options || {};
      var element = (options.dropdownParent) ? '.modal-select2-ajax' : '.select2-ajax';
      const dropdownParent = options.dropdownParent || $(document.body);
      const dropdownPosition = options.dropdownPosition || 'auto';
      const hideSearchBox = options.hideSearchBox || 10;

      $(element).select2({
        width: '100%',
        dropdownParent: dropdownParent,
        dropdownPosition: dropdownPosition,
        allowClear: true,
        //minimumInputLength: 3,
        minimumResultsForSearch: hideSearchBox,
        ajax: {
          url: function (params) {
            return $(this).data('url');
          },
          dataType: 'json',
          delay: 1000,
          cache: true,
          data: function (params) {
            var query = {
              query: params.term,
              page: params.page || 1,
            }
            return query;
          }
        }
      });
    };

    loadSelect2(options) {
      var options = options || {};
      var element = (options.dropdownParent) ? '.modal-select2' : '.select2';
      const dropdownParent = options.dropdownParent || $(document.body);
      const dropdownPosition = options.dropdownPosition || 'auto';

      $(element).select2({
        width: '100%',
        dropdownParent: dropdownParent,
        dropdownPosition: dropdownPosition
      });
    };

    loadSelect2Cascade(parent, child) {
      const operation = this;
      const parent_select = $(parent);
      const child_select = $(child);
      const url = child_select.data('url');

      parent_select.on("change", function () {
        $(child).val(null).trigger('change');
      });

      $(child).select2({
        allowClear: true,
        placeholder: $(this).data('placeholder') || "",
        ajax: {
          url: url,
          dataType: 'json',
          delay: 1000,
          cache: true,
          data: function (params) {
            var query = {
              q: params.term,
              object_id: parent_select.find('option:selected').val() || "",
              page: params.page || 1,
            }
            subquery = JSON.parse($(parent).data('query') || '{}')
            $.extend(query, subquery)
            return query;
          }
        }
      })
    };

    addOptionSelect2(element, option) {
      $(element).append(option).trigger('change');
    };

    loadTooltips() {
      this.hideLoadTooltips();

      $('[data-toggle="tooltip"]').tooltip({
        html: true
      });
    };

    hideLoadTooltips() {
      $('body').on('click', function (e) {
        $('[data-toggle="tooltip"]').each(function () {
          if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.tooltip').has(e.target).length === 0) {
            $(this).tooltip('hide');
          }
        });
      })

      $('[data-toggle="tooltip"]').tooltip('hide');
    };

    hideAjaxIndicator() {
      $('.js-ajax-indicator').removeClass("d-block").addClass("d-none");
    };

    maskedNumber() {
      const operation = this;

      $(operation.ui.jsFloatNumber).each(function () {
        $(this).inputmask({
          alias: 'numeric',
          groupSeparator: ',',
          digits: 2,
          digitsOptional: false,
          allowMinus: false
        });
      });

      $(operation.ui.jsIntNumber).each(function () {
        $(this).inputmask({
          alias: 'numeric',
          groupSeparator: ',',
          digits: 0,
          digitsOptional: false,
          allowMinus: false
        });
      });
    };

    resetFilter() {
      const operation = this;

      $(operation.ui.resetFilter).on('click', function (event) {
        setTimeout(function () {
          $(".select2").val("").trigger('change');
          $(".select2-ajax").val("").trigger('change');
          $(".js-daterangepicker").val("");
          if ($("#cutoff_date_eq").length >= 1) {
            $("#cutoff_date_eq")[0].valueAsDate = new Date();
          }
        }, 500);
      });
    };

    textUpperCase() {
      const operation = this;

      $(operation.ui.textUpperCase).keyup(function () {
        $(this).val($(this).val().toUpperCase());
      });
    };

    renderTableRow(rowId, tableRow, options) {
      const operation = this;
      var options = options || {};
      var count = options.count;
      options.callback = options.callback || function () { };

      $(rowId).fadeOut(function () {
        $(this).html(tableRow);
        $(this).fadeIn(function () {
          if (count > 0) {
            options.callback.call(operation);
          }
        });
      });
    };

    getUrl(url, params) {
      var params = params || {};

      $.ajax({
        method: "GET",
        url: url,
        data: params
      });
    };

    loadDatepickersForMonth = function (options) {
      var options = options || {};
      var query = options.query
      var custom_end_date = options.allow_end_date

      if (typeof query != 'undefined') {
        $datepickers = $(query).find('input.js-month-datepicker');
      } else {
        $datepickers = $('.js-month-datepicker');
      }

      var startDate = new Date();

      $datepickers.datepicker({
        autoclose: true,
        language: "es",
        minViewMode: 1,
        format: 'mm-yyyy',
        startDate: '09-2021',
        todayHighlight: true
      }).on('changeDate', function (selected) {
        startDate = new Date(selected.date.valueOf());
        startDate.setDate(startDate.getDate(new Date(selected.date.valueOf())));
      });

      if (typeof custom_end_date != 'undefined') {
        var endDate = moment().format('mm-yyyy');
        $datepickers.datepicker("setEndDate", endDate);
      }
    };

    loadDateMonthFromTo(from, to) {
      $(from).on('change', function (e, date) {
        $(to).datepicker("setStartDate", $(this).val());
      });

      $(to).on('change', function (e, date) {
        $(from).datepicker("setEndDate", $(this).val());
      });
    };

    loadDateValidRange(element, min, max) {
      var min = min || "2021-10-13";
      var max = max || moment().add('days', 7).format('YYYY-MM-DD');

      $(element).attr('min', min);
      $(element).attr('max', max);
    };

    loadData(element, data) {
      $(element).data(data);
    };

    setCurrentDate(elementObject) {
      var elementObject = elementObject || '.js-current-date';
      var htmlElement = $(elementObject)

      htmlElement.each(function (idx, element) {
        $(element)[0].valueAsDate = new Date();
      });
    };

    passwordRequirements() {
      $(".pr-password").passwordRequirements({});
    };

    need2FactorAuthentication(needConfigure) {
      if (needConfigure == 'true') {
        bootbox.alert({
          message: '<p class="text-center">Para proteger su cuenta le recomendamos configurar la Verificación en 2 pasos. <br>Pulse Configurar para realizar la acción.</p>',
          buttons: {
            ok: {
              label: 'Configurar',
              className: 'btn-success'
            },
          },
          callback: function () {
            $.ajax({
              method: "GET",
              url: 'two_factor_settings/new',
              dataType: 'script',
            }).done(function () {
              console.log("Done!");
            }).fail(function () {
              console.log("error");
            });
          }
        });
      };
    };

    clearLoadFileInput() {
      var fileInputs = $("input[type='file']");

      fileInputs.each(function (idx, element) {
        $(element).val("").trigger('change');
        $(element).closest(".custom-file").find(".custom-file-label").html(i18n.t('commons.choose_file'));
      });
    };

    loadCustomFileInput() {
      this.clearLoadFileInput();

      $('.custom-file-input').on('change', function () {
        let fileName = $(this).val().split('\\').pop();
        $(this).next('.custom-file-label').addClass("selected").html(fileName);
      });

      $('.custom-file-input').on('blur', function () {
        $(this).change();
      });
    };

    hideBootBox() {
      setTimeout(function () {
        $('.bootbox.modal').modal('hide');
      }, 500);
    };

    convertImgToBase64(source, target) {
      $(source).on('change', function (element) {
        var img = element.target.files[0];
        var reader = new FileReader();

        reader.onloadend = function () {
          $(target).val(reader.result);
        }

        if (img) {
          reader.readAsDataURL(img);
        }
      });
    };

    modalCloseBtn() {
      $(".js-close").on('click', function () {
        location.reload();
      });
    };

    submitFormRequiresApproval(message) {
      const operation = this;

      $(operation.ui.jsBtnSubmit).on('click', function (evt) {
        evt.preventDefault();
        evt.stopImmediatePropagation();
        evt.stopPropagation();

        var dialog = bootbox.confirm({
          message: message,
          buttons: {
            confirm: {
              label: 'Sí',
              className: 'btn-success'
            },
            cancel: {
              label: 'No',
              className: 'btn-danger'
            }
          },
          callback: function (result) {
            if (result) {
              $(operation.ui.jsFormValidation).submit();
            } else {
              dialog.modal('hide');
              return false;
            }
          }
        });

        return false;
      });
    };

    loadConfirmModalRemote() {
      const operation = this;

      $(operation.ui.jsConfirmModalRemoteLink).on('click', function () {
        var url = $(this).data('url');
        var message = $(this).data('message') || i18n.t('bootbox.' + $(this).data('locale'));
        var method = $(this).data('method') || 'PUT';

        Swal.fire({
          title: message,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#69BE5A',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed) {
            $.ajax({
              method: method,
              url: url,
              dataType: 'script'
            }).done(function () {
            }).fail(function () {
              console.log("error");
            });
          }
        })
      });
    }
  };

  window.Common = Common;
})();
