// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const lang = 'es' //document.documentElement.lang;

require.context('../images', true)

import "../stylesheets/application.scss"
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()
import { i18n } from "../config/i18n"
global.i18n = i18n;
window.i18n = i18n;
import 'bootstrap'
import 'jquery-slimscroll'
import 'node-waves'
import screenfull from 'screenfull';
window.screenfull = screenfull;
import 'jquery-validation'
window.jQuery = $;
window.$ = $;
var moment = require('moment')
global.moment = moment;
window.moment = moment;
import 'select2'
import Swal from 'sweetalert2'
global.Swal = Swal;
window.Swal = Swal;
import 'jquery-file-download';
import bootbox from 'bootbox';
global.bootbox = bootbox;
window.bootbox = bootbox;
import 'daterangepicker'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.es'
import SignaturePad from 'signature_pad'
window.SignaturePad = SignaturePad;
import '../src/helpers/calculator_loan_dates'
import '../src/common';
import '../src/credit_scores/credit_score';
import '../src/loans/loan';
import '../src/device_manager/device_manager';
import '../src/notifications/notification';
import '../src/customers/customer';
import '../src/customer_consents/customer_consent';
import '../src/payments/payment';
import '../src/payment_agreements/payment_agreement';
import '../src/reports/collection_goal';
import '../src/sales/sale';

require('src/app.js')
require('src/plugins/bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker.js')
require('src/plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css')
require('src/plugins/password-requirements/password-requirements.js')
require('src/plugins/password-requirements/password-requirements.scss')
require('jquery-validation/dist/localization/messages_' + lang)
require("inputmask/dist/jquery.inputmask")
require('inputmask/dist/bindings/inputmask.binding')
require("@nathanvda/cocoon")
import * as Routes from '../routes';
window.Routes = Routes;
